//列表（带有分页数据）的混入
import {mapState} from "vuex";
import {DICT} from "@/utils/dict.js";

export const ListMixin = {
    data: function () {
        return {
            dict: DICT,
            listGetUrl: "",
            listMethod: "get",
            advanced: false, //是否显示高级查询部分
            queryParams: {},
            filteredInfo: null,
            sortedInfo: null,
            paginationInfo: null,
            dataSource: [],
            selectedRowKeys: [],
            loading: false,
            pagination: {
                pageSizeOptions: ["10", "20", "30", "40", "100"],
                defaultCurrent: 1,
                defaultPageSize: 10,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total, range) =>
                    `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
            },
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.account.user,
        }),
    },
    methods: {
        toggleAdvanced() {
            this.advanced = !this.advanced;
            if (!this.advanced) {
                this.queryParams.createTimeFrom = "";
                this.queryParams.createTimeTo = "";
            }
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        search(newPage) {
            let {sortedInfo, filteredInfo} = this;
            let sortField, sortOrder;

            // 获取当前列的排序和列的过滤规则
            if (sortedInfo) {
                sortField = sortedInfo.field;
                sortOrder = sortedInfo.order;
            }
            if (this.paginationInfo) {
                if (+newPage > 0) {
                    this.paginationInfo.current = +newPage;
                } else {
                    // this.paginationInfo.current = 1;
                }
            }
            this.fetch({
                sortField: sortField,
                sortOrder: sortOrder,
                ...this.queryParams,
                ...filteredInfo,
            });
        },
        reset() {
            // 取消选中
            this.selectedRowKeys = [];
            // 重置分页
            if (this.$refs.TableInfo.pagination) {
                this.$refs.TableInfo.pagination.current =
                    this.pagination.defaultCurrent;
                if (this.paginationInfo) {
                    this.paginationInfo.current = this.pagination.defaultCurrent;
                    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
                }
            }
            // 重置列过滤器规则
            this.filteredInfo = null;
            // 重置列排序规则
            this.sortedInfo = null;
            // 重置查询参数
            this.queryParams = {};
            // 清空时间选择
            if (this.$refs.createTime) {
                this.$refs.createTime.reset();
            }
            this.search();
        },
        handleTableChange(pagination, filters, sorter) {
            // 将这三个参数赋值给Vue data，用于后续使用
            this.paginationInfo = pagination;
            this.filteredInfo = filters;
            this.sortedInfo = sorter;

            this.fetch({
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...this.queryParams,
                ...filters,
            });
        },
        fetch(params = {}) {
            // 显示loading
            this.loading = true;

            if (this.paginationInfo && this.$refs.TableInfo.pagination) {
                // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
                this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
                this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
                params.pageSize = this.paginationInfo.pageSize;
                params.page = this.paginationInfo.current;
            } else {
                // 如果分页信息为空，则设置为默认值
                params.pageSize = this.pagination.defaultPageSize;
                params.page = this.pagination.defaultCurrent;
            }
            if (this.beforeFetch) {
                this.beforeFetch(params); //Fetch前做特殊处理
            }
            let promise = null;
            let method = this.listMethod ? this.listMethod.toLowerCase() : "get";
            if (method === "postjson") {
                promise = this.$postJson(this.listGetUrl, {...params});
            } else if (method === "post") {
                promise = this.$post(this.listGetUrl, {...params});
            } else {
                promise = this.$get(this.listGetUrl, {...params});
            }
            promise
                .then((r) => {
                    let data = r.data;
                    const pagination = {...this.pagination};
                    this.pagination = pagination;

                    if (this.afterFetch) {
                        this.afterFetch(data); //Fetch后做特殊处理
                    }
                    this.dataSource = data.records || data || [];
                    pagination.total = data.total || this.dataSource.length;
                    // 数据加载完毕，关闭loading
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
